import React from 'react'
import { Container, Row, Col, Dropdown, DropdownButton } from 'react-bootstrap'
import { useSearchParams } from '../../libs/hooks'
import parse from 'html-react-parser'
import { MappedImage } from '../Image'

export const Header = ({
  phoneNumber,
  phoneNumberExtras,
  hoursAndInfo,
  emailAddress,
  contactWrapperOne,
  contactWrapperTwo,
  languageOptions,
  languageSelectorText,
  primaryLogo,
  secondaryLogo,
  headerPrimaryLogo,
  headerSecondaryLogo,
  slug,
}) => {
  let url = useSearchParams(slug).split('?')
  return (
    <Container fluid as="header" className="header-outer p-sm-1 p-md-3">
      <Row className="header-inner px-sm-1 px-md-4">
        <Col className="logo-wrapper">
          {(headerPrimaryLogo || primaryLogo) && (
            <MappedImage
              className="mr-2"
              image={headerPrimaryLogo || primaryLogo}
              alt={`${headerPrimaryLogo?.title || primaryLogo?.title}`}
            />
          )}
          {(headerSecondaryLogo || secondaryLogo) && (
            <MappedImage
              className="py-2"
              image={headerSecondaryLogo || secondaryLogo}
              alt={`${headerSecondaryLogo?.title || secondaryLogo?.title}`}
            />
          )}
        </Col>
        <Col className="contact-block">
          <div className="contact-block-inner my-2">
            <div className="p-0 m-0">
              {contactWrapperOne && contactWrapperOne + ' '}
              <strong>
                <a href={'tel:' + phoneNumber}>{phoneNumber}</a>
              </strong>
              {phoneNumberExtras && ' ' + phoneNumberExtras}
            </div>
            {hoursAndInfo && (
              <div className="hours-and-info-block">
                <p>{parse(hoursAndInfo)}</p>
              </div>
            )}
            <div className="p-0 m-0">
              {contactWrapperTwo && ' ' + contactWrapperTwo + ' '}
              <strong>
                <a href={'mailto:' + emailAddress}>{emailAddress}</a>
              </strong>
            </div>
            <div>
              {languageOptions && (
                <DropdownButton
                  className="mt-2 language-selector"
                  id="dropdown-basic-button"
                  title={languageSelectorText}
                >
                  <Dropdown.Item href={`/en/${slug}/?${url[1]}`}>
                    {languageOptions[0]}
                  </Dropdown.Item>
                  <Dropdown.Item href={`/es/${slug}/?${url[1]}`}>
                    {languageOptions[1]}
                  </Dropdown.Item>
                </DropdownButton>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export const mapHeaderProps = props => {
  const {
    phoneNumber,
    phoneNumberExtras,
    hoursAndInfo,
    emailAddress,
    contactWrapperOne,
    contactWrapperTwo,
    languageOptions,
    languageSelectorText,
    primaryLogo,
    secondaryLogo,
    headerPrimaryLogo,
    headerSecondaryLogo,
    slug,
  } = props
  return {
    phoneNumber,
    phoneNumberExtras,
    hoursAndInfo,
    emailAddress,
    contactWrapperOne,
    contactWrapperTwo,
    languageOptions,
    languageSelectorText,
    primaryLogo,
    secondaryLogo,
    headerPrimaryLogo,
    headerSecondaryLogo,
    slug,
  }
}
